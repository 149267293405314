<template>
  <div class="layout-header">
    <LayBreadcrumb />
    <LayUser />
  </div>
</template>
<script>
import LayBreadcrumb from "@/components/LayBreadcrumb";
import LayUser from "@/components/LayUser";
export default {
  components: {
    LayBreadcrumb,
    LayUser,
  },
};
</script>
<style lang="scss">
.layout-header {
  display: flex;
  justify-content: space-between;
  height: 64px;
  background: #ffffff;
}
</style>
