<template>
  <el-dialog
    custom-class="customdialog"
    title="修改密码"
    :visible="editpassworddialog"
    width="55%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="con">
      <div class="form2">
        <el-form ref="form2" :model="form2" label-width="0">
          <el-form-item required>
            <div class="label">
              <span class="red">*</span>新密码：<span class="tips"
                >1.密码长度不能低于6个字符；2.密码必须由数字、字母、符号等至少两种类型混合组成</span
              >
            </div>
            <el-input v-model="form2.newPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item required>
            <div class="label"><span class="red">*</span>确认新密码：</div>
            <el-input v-model="form2.newPassword1" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="wrapbtn">
        <div class="btn cancel" @click="handlecancel">取消</div>
        <div class="btn" @click="handleok">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { updatepassword } from '@/api/patientManage'
import { mapGetters } from "vuex"
export default {
  name: 'Editpassword',
  data () {
    return {
      form2: {
        newPassword: '',
        newPassword1: '',
        oldPassword: ''
      },
    }
  },
  computed: {
    ...mapGetters(["editpassworddialog"])
  },
  methods: {
    handlecancel () {
      this.$store.commit("user/SET_ClOSEEDITPASSWORD")
    },
    async handleok () {
      if (this.form2.newPassword == "") {
        return this.$message({
          type: "error",
          message: "新密码不能为空",
        })
      } else if (!this.validatePassword(this.form2.newPassword)) {
        return this.$message({
          type: "error",
          message: "密码格式不正确",
        })
      }
      if (this.form2.newPassword != this.form2.newPassword1) {
        return this.$message({
          type: "error",
          message: "确保两次输入的密码一致",
        })
      }
      let {code,msg } = await updatepassword({
        newPassword:this.$md5(this.form2.newPassword),
        newPassword1:this.$md5(this.form2.newPassword1),
      })
      if(code == 200){
           this.$message({
            type: "success",
            message: msg,
          })
          this.$store.commit("user/SET_ClOSEEDITPASSWORD")
      }else {
        return this.$message({
            type: "error",
            message: msg,
          })
      }
    },

    handleClose () {
      this.$store.commit("user/SET_ClOSEEDITPASSWORD")
    },


    validatePassword (password) {
      // 密码长度不能低于6个字符
      if (password.length < 6) {
        return false
      }
      // 密码必须由数字、字母、符号等至少两种类型混合组成
      var regex = /^(?=.*[0-9])(?=.*[a-zA-Z!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]+$/
      return regex.test(password)
    },
  },

}
</script>

<style  lang="scss" scoped>
::v-deep .el-dialog.customdialog {
  .red {
    color: #db001c;
  }
  .step {
    padding-top: 40px;
  }

  .is-finish {
    .el-step__icon.is-text {
      background: #409eff;
      .el-step__icon-inner {
        color: #fff;
      }
    }
  }
  .con {
    .tips {
      color: rgba(0, 0, 0, 0.4);
    }

    margin: 0 50px;
    padding-top: 40px;
    position: relative;
    height: 400px;
    .wrapbtn {
      position: absolute;
      bottom: 64px;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .form1 {
      .el-input__inner {
        background: #f5f7fa;
      }
    }
    .label {
      height: 22px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      line-height: 22px;
      margin-bottom: 8px;
    }
    .btn {
      cursor: pointer;
      width: 60px;
      height: 32px;
      line-height: 32px;
      background: #1e7aff;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      box-sizing: border-box;
      margin: 0 12px;
    }
    .cancel {
      background: transparent;
      border: 1px solid #DCDFE6;
      color: #303133;
    }
    .form3 {
      padding-top: 60px;
      height: 100%;
      .el-icon-success {
        color: #00cf85;
        font-size: 67px;
        margin-bottom: 10px;
      }
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .el-alert--warning.is-light {
    background-color: #fcf6ec;
    padding: 15px;
    color: #e6a23c;
    .el-alert__title {
      font-size: 14px;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  border-radius: 4px;
  .el-dialog__header {
    text-indent: 16px;
    font-size: 18px;
    height: 58px;
    line-height: 58px;
    background: #1e7aff;
    border-radius: 4px 4px 0px 0px;
    color: #fff;
    padding: 0;
    position: relative;

    .el-icon-full-screen {
      position: absolute;
      right: 48px;
      top: 20px;
    }
    .el-dialog__close,
    .el-dialog__title {
      color: #fff;
    }
  }
}
</style>