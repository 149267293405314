<template>
  <div class="layout-aside">
    <el-scrollbar style="height: 100%">
      <el-menu
        unique-opened
        :default-active="asideValue"
        mode="vertical"
        :show-timeout="200"
        @select="selectChange"
      >
        <sidebar-item
          :menu="menu"
          first
          :props="website.menu.props"
        ></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import sidebarItem from "./sidebarItem"
import { mapGetters, mapMutations } from "vuex"
export default {
  components: { sidebarItem },
  inject: ["index"],
  data () {
    return {

    }
  },
  created () {
    this.index.openMenu()
  },
  methods: {
    selectChange (index) {
      this.setAside(index)

    },
    ...mapMutations("setting", ["setAside"]),
  },
  computed: {
    ...mapGetters(["menu", 'website', 'asideValue']),

  },
};
</script>
<style lang="scss">
.layout-aside {
  margin-top: 30px;
  .el-menu {
    background: #3181f1 !important;
    border: none !important;
    li + li {
      margin-top: 12px;
    }
    .el-menu-item {
      height: 42px;
      border-radius: 5px;
      line-height: 42px;
      font-size: 14px;
      color: #ffffff;
      i {
        color: #ffffff;
      }
    }
    .el-menu-item:hover {
      background: #fff !important;
      color: rgba(0, 0, 0, 0.9) !important;
      i {
        color: #3181f1;
      }
    }
    .is-active {
      background: #fff;
      color: rgba(0, 0, 0, 0.9);
      i {
        color: #3181f1;
      }
    }
  }
}
</style>
