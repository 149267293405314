<template>
  <div class="layout-content">
    <el-container>
      <el-aside class="my-aside" width="200px">
        <LayLogo />
        <LayAside />
      </el-aside>
      <el-container>
        <el-header>
          <LayHeader />
        </el-header>
        <el-main>
          <LayMain />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import LayLogo from "@/components/LayLogo"
import LayHeader from "@/components/LayHeader"
import LayMain from "@/components/LayMain"
import LayAside from "@/components/LayAside"
import {validatenull} from "@/utils/validate";
import {calcDate} from "@/utils/date.js";
import {getStore} from "@/utils/store.js";
export default {
  name: "layout",
  provide () {
    return {
      index: this
    }
  },
  data() {
      return {
        logoutTimer:null,
        inactivityTimeout: 20 * 60 * 1000, //20分钟  // 设置无操作的超时时间（以毫秒为单位）
        //刷新token锁
        refreshLock: false,
        //刷新token的时间
        refreshTime: ""
      };
    },
 async created() {
   
      //实时检测刷新token
      // this.refreshToken();
      document.addEventListener("mousemove", this.resetLogoutTimer);
      this.resetLogoutTimer();
    },
  methods: {
    resetLogoutTimer() {
      console.log(this.inactivityTimeout)
      clearTimeout(this.logoutTimer);
      this.logoutTimer = setTimeout(this.logout, this.inactivityTimeout);
    },
   async logout() {
        let   res  = await  this.$store.dispatch("user/refreshToken")
         if(res.code != 200){
           this.$store.dispatch('user/LogOut').then(() => router.push({path: '/login'}))
        }
      },
     // 定时检测token
      refreshToken() {
        this.refreshTime = setInterval(() => {
          const token = getStore({
            name: "token",
            debug: true
          }) || {};
          const date = calcDate(token.datetime, new Date().getTime());

          if (validatenull(date)) return ;
         
          if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
            this.refreshLock = true;
            this.$store
              .dispatch("user/refreshToken")
              .then(() => {
                this.refreshLock = false;
              })
              .catch(() => {
                this.refreshLock = false;
              });
          }
        }, 3000);
      },
    openMenu (item = {}) {
      this.$store.dispatch("user/GetMenu", item.id).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true)
        }
      })
    },
  },
  components: {
    LayLogo,
    LayHeader,
    LayMain,
    LayAside,
  },
};
</script>
<style lang="scss" scoped>
.layout-content {
  height: 100%;

  .el-container {
    height: 100%;
  }
  .my-aside {
    padding: 40px 5px 0 5px;
    box-sizing: border-box;
    height: 100%;
    background: #3181f1;
  }
  .el-main {
    padding: 0 !important;
  }
}
</style>
