import Layout from '@/views/layout'

export default [
  {
    path: '/patientManage',
    component: Layout,
    redirect: '/patientManage/manage',
    children: [
      {
        name: 'manage',
        path: 'manage',
        meta: {
          title: '患者管理',
        },
        component: () => import('@/views/patientManage/manage'),
      },
      {
        path: '/patientDetail',
        name: 'patientDetail',
        meta: {
          title: '患者详情',
        },
        component: () => import('@/views/patientDetail'),
      },
    ],
  },

]
