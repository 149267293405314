<template>
  <router-link to="/" class="layout-logo">
    <img @click="setAside('/')" src="@/assets/images/lay_logo.png" alt="" />
    <div @click="setAside('/')" class="desc">老年认知功能障碍平台</div>
  </router-link>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations('setting', ['setAside']),
  },
}
</script>
<style lang="scss">
.layout-logo {
  text-decoration: none;
  img {
    width: 72px;
    display: block;
    margin: 0 auto 12px;
  }
  .desc {
    height: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    line-height: 16px;
    padding-bottom: 20px;
  }
}
</style>
