export default {
  namespaced: true,
  state: {
    asideValue: "/",
    breadCrumbValue: '患者管理',


  },
  getters: {
    asideValue (state) {
      return state.asideValue
    },
    breadCrumbValue (state) {
      return state.breadCrumbValue
    }
  },
  mutations: {
    setAside (state, val) {
      state.asideValue = val
    },
    setBreadcrumb (state, val) {
      state.breadCrumbValue = val
    },
  },
  actions: {
    // setNewAside({ commit, dispatch }) {
    //   commit("setAside", val);
    // },
  },
  modules: {},
}
