import request from "@/utils/request.js";

//获取验证码
export function captcha() {
  return request({
    url: "/adhd-auth/oauth/captcha",
    method: "get",
  });
}
//清除缓存（后台）
export function clearCache() {
  return request({
    url: "/adhd-auth/oauth/clear-cache",
    method: "get",
  });
}
//登录
export function login(data) {
  return request({
    url: "/adhd-auth/oauth/token",
    method: "post",
		// header: 'multipart/form-data',
    data
  });
}
export function ifupdatepassword (params) {
  //修改密码
  return request({
    url: "/adhd-user/if_update-password",
    header: "application/json",
    method: "get",
    params
  })
}

//其实，也不一定就是params，也可以是 query 还有 data 的呀！
//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。
