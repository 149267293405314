import Vue from "vue"
import App from "./App"
import router from "./router"

import store from '@/store/index.js'
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import md5 from "js-md5"
import { baseURL } from "./utils/netconfig"
import website from '@/config/website'
import directive from '@/utils/directive'
Vue.prototype.$md5 = md5
Vue.prototype.$baseUrl = baseURL
Vue.use(ElementUI)
Vue.use(directive)

Vue.config.productionTip = false
Vue.prototype.website = website
router.beforeEach(async (to, from, next) => {
  let { access_token } = JSON.parse(localStorage.getItem('userInfo')) || ''
  if (!access_token && to.name !== 'login') {
    next({ name: 'login' })
  } else if (access_token && to.name == 'login') {
    next({ name: 'patientManage' })
  } else {
    next()
  }
})

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App)

})
