<template>
  <div id="app">
    <router-view />
    <InitEditpassword />
    <Editpassword />
  </div>
</template>

<script>
import InitEditpassword from "@/components/InitEditpassword"
import Editpassword from "@/components/Editpassword"
export default {
  name: 'App',
  components: {
    Editpassword,
    InitEditpassword
  },
  data () {
    return {
      
    }
  },
  methods:{
    
  },
  created () {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
    }

    
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  
}
</script>

<style>
.el-tabs__nav-scroll {
  position: relative;
  left: 40px;
}
#app {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* margin-top: 60px; */
}

* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
</style>
