const getters = {
  isshowdialog: state => state.user.isshowdialog,
  userInfo: state => state.user.userInfo,
  editpassworddialog: state => state.user.editpassworddialog,
  website: state => state.user.website,
  menu: state => state.user.menu,
  asideValue: state => state.setting.asideValue,
  permission: state => state.user.permission,
}
export default getters
