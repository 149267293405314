/**
 * 全局配置文件
 */
export default {
  key: 'sunkidadmin',
  tokenTime:1201,
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      label: 'name',
      path: 'path',
      icon: 'source',
      children: 'children'
    }
  },
  propsDefault: {
    label: 'label',
    path: 'path',
    icon: 'icon',
    children: 'children',
    isOpen: 'isOpen'
  }

}
