import request from "@/utils/request.js"
export function patientList (params) {
  //患者分页列表
  return request({
    url: "/adtxUserProfile/page",
    method: "get",
    params,
  })
}
export function patientRemove (data) {
  //删除患者
  return request({
    url: "/adtxUserProfile/remove",
    header: "application/json",
    method: "post",
    data,
  })
}
export function patientDetail (params) {
  //患者信息详情
  return request({
    url: "/adtxUserProfile/detail",
    method: "get",
    params,
  })
}
export function patientScaleDetail (params) {
  //获取评测对应详情
  return request({
    url: "/scale/getLastScaleConclusionScore",
    method: "get",
    params,
  })
}
export function patientReport (params) {
  //获取检查报告
  return request({
    url: "/adtxUserProfileReport/listReport",
    method: "get",
    params,
  })
}
export function drugDaysList (params) {
  //echarts数据
  return request({
    url: "/userProfileMedicinal/drugDaysList",
    method: "get",
    params,
  })
}
export function drugEatList (params) {
  //图表tab
  return request({
    url: "/userProfileMedicinal/drugEatList",
    method: "get",
    params,
  })
}
export function patientScaleList (params) {
  //获取评测对应详情
  return request({
    url: "/scale/getScaleConclusionScore",
    method: "get",
    params,
  })
}
export function patientScaleListDetail (params) {
  //获取评测对应详情
  return request({
    url: "/scale/getScaleConclusionScoreDetailPC",
    method: "get",
    params,
  })
}
export function profileList (params) {
  //获取评测对应详情
  return request({
    url: "/adtxUserProfile/list",
    method: "get",
    params,
  })
}

export function importBindingUserProfile (data) {
  //批量照料者绑定患者
  return request({
    url: "adtxUserProfile/importBindingUserProfile",
    method: "post",
    data,
  })
}

export function adtxUserProfileImportRecord (params) {
  //导入记录
  return request({
    url: "adtxUserProfileImportRecord/page",
    method: "get",
    params,
  })
}


export function adtxUserProfileImportErroRecord (params) {
  //导入失败
  return request({
    url: "/adtxUserProfileImportDetailRecord/page",
    method: "get",
    params,
  })
}
export function getmenu (params) {
  //获取菜单
  return request({
    url: "/adhd-system/menu/routes",
    method: "get",
    params,
  })
}

export function getmenubuttons (params) {
  //获取按钮
  return request({
    url: "/adhd-system/menu/buttons",
    method: "get",
    params,
  })
}
export function updatepassword (data) {
  //修改密码
  return request({
    url: "/adhd-user/update-password",
    header: "application/json",
    method: "post",
    params:data
  })
}

export function refreshToken (params) {
  //刷新token
  return request({
    url: "/adhd-auth/oauth/token",
    header: "application/json",
    method: "post",
    params,
  })
}






