<template>
  <div class="layout-bread">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item  :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ breadCrumbValue }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    //用这样方式取值
    ...mapGetters("setting", ["asideValue", "breadCrumbValue"]),
  },
};
</script>
<style lang="scss" scoped>
.layout-bread {
  .el-breadcrumb {
    line-height: 64px;
    .el-breadcrumb__item {
      .el-breadcrumb__inner {
        font-size: 14px !important;
        color: rgba(0, 0, 0, 0.9) !important;
      }
    }
  }
}
</style>
