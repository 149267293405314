export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false,
    },
  },
  {
    path: '/',
    name: 'patientManage',
    redirect: '/patientManage',
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    name: '404',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false,
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
]
