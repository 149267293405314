/**
* v-hasPermi 操作权限处理
*/
export default {
  inserted (el, binding) {
    const { value } = binding
    if (!value) {
      el.parentNode && el.parentNode.removeChild(el)
    }



  }
}
