<template>
  <div class="layout-user">
    <img :src="avatar" alt="" />
    <el-dropdown trigger="click">
      <span class="user-name">
        {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <template>
            <span @click="editPassword">修改密码</span>
          </template>
        </el-dropdown-item>
        <!-- <el-dropdown-item>
          <template>
            <span @click="clearCache">清除缓存</span>
          </template>
        </el-dropdown-item> -->
        <el-dropdown-item>
          <template>
            <span @click="loginOut">退出登录</span>
          </template>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { mapMutations } from "vuex"
import { clearCache } from "@/api/login"
import { resetRouter } from "@/router"
export default {
  data () {
    return {
      userName: "",
      avatar: "",

    }
  },
  created () {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    this.userName = userInfo.user_name
    this.avatar = userInfo.avatar
    // this.baseUrl = this.$baseUrl;
  },
  methods: {
    editPassword () {
      this.$store.commit("user/SET_OPENEDITPASSWORD")
    },
    ...mapMutations("setting", ["setAside"]),
    clearCache () {
      clearCache().then((res) => {
        console.log(res, "rererere")
      })
    },
    loginOut () {
      this.$confirm("是否确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear()
          this.$message({
            type: "success",
            message: "退出成功!",
          })
          this.setAside("/")
          resetRouter()
          this.$router.push({
            path: "/login",
          })
        })
        .catch(() => { })
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-user {
  line-height: 64px;
  img {
    height: 32px;
    width: 32px;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
  }
  .user-name {
    display: inline-block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
  }
}
::v-deep .el-dropdown {
  // margin-top: 16px;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
</style>
