import {setToken, setRefreshToken, removeToken, removeRefreshToken} from '@/utils/auth'
import { isURL, validatenull } from '@/utils/validate'
import { setStore, getStore } from '@/utils/store'
import { getmenu, getmenubuttons,refreshToken } from '@/api/patientManage'
import { deepClone } from '@/utils/util'
import website from '@/config/website'
function addPath (ele, first) {
  const menu = website.menu
  const propsConfig = menu.props
  const propsDefault = {
    label: propsConfig.label || 'name',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children'
  }
  const icon = ele[propsDefault.icon]
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon
  const isChild = ele[propsDefault.children] && ele[propsDefault.children].length !== 0
  if (!isChild) ele[propsDefault.children] = []
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + '/index'
  } else {
    ele[propsDefault.children].forEach(child => {
      addPath(child)
    })
  }

}
export default {
  namespaced: true,
  state: {
    website: website,
    isshowdialog: false,
    editpassworddialog: false,
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    menu: getStore({ name: 'menu' }) || [],
    menuAll: getStore({ name: 'menuAll' }) || [],
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    permission: getStore({ name: 'permission' }) || {},
    token: getStore({name: 'token'}) || '',
    refreshToken: getStore({name: 'refreshToken'}) || '',
  },
  mutations: {
    SET_OPENEDITPASSWORD: (state) => {
      state.editpassworddialog = true
    },
    SET_ClOSEEDITPASSWORD: (state) => {
      state.editpassworddialog = false
    },
    SET_ClOSEDIALOG: (state) => {
      state.isshowdialog = false
    },
    SET_OPENDIALOG: (state) => {
      state.isshowdialog = true
    },
    SET_USERINFO (state, val) {
      state.userInfo = val
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore({ name: 'menu', content: state.menu })
    },
    SET_MENU_ALL: (state, menuAll) => {
      let menu = state.menuAll
      menuAll.forEach(ele => {
        if (!menu.find(item => item.label === ele.label && item.path === ele.path)) {
          menu.push(ele)
        }
      })
      state.menuAll = menu
      setStore({ name: 'menuAll', content: state.menuAll })
    },
    SET_MENU_ALL_NULL: (state) => {
      state.menuAll = []
      setStore({ name: 'menuAll', content: state.menuAll })
    },
    SET_PERMISSION: (state, permission) => {
      let result = []

      function getCode (list) {
        list.forEach(ele => {
          if (typeof (ele) === 'object') {
            const chiildren = ele.children
            const code = ele.code
            if (chiildren) {
              getCode(chiildren)
            } else {
              result.push(code)
            }
          }
        })
      }

      getCode(permission)
      state.permission = {}
      result.forEach(ele => {
        state.permission[ele] = true
      })
      setStore({ name: 'permission', content: state.permission })
    },

    SET_TOKEN: (state, token) => {
      setToken(token);
      state.token = token;
      setStore({name: 'token', content: state.token})
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken)
      state.refreshToken = refreshToken;
      setStore({name: 'refreshToken', content: state.refreshToken})
    },
  },

  actions: {
    //退出
    LogOut({commit}) {
      return new Promise(resolve => {
        commit('SET_MENU_ALL_NULL', []);
        commit('SET_MENU', []);
        localStorage.clear()
        removeToken();
        removeRefreshToken();
        resolve();
      })
    },
    setUserinfo({ commit, dispatch },val){
      return new Promise(resolve => {
        commit('SET_USERINFO', val)
        commit('SET_TOKEN', val.access_token);
        commit('SET_REFRESH_TOKEN', val.refresh_token);
        resolve(val)
      })
      
    },
    //刷新token
    refreshToken({state, commit}, userInfo) {
      window.console.log('handle refresh token');
      return new Promise((resolve, reject) => {
        refreshToken({refresh_token:state.refreshToken,grant_type:'refresh_token',scope:'all'}).then((res) => {
          const data = res.data;
          console.log(data,'data')
          commit('SET_TOKEN', data.access_token);
          commit('SET_REFRESH_TOKEN', data.refresh_token);
          resolve(res)
        })
      })
    },
    //获取系统菜单
    GetMenu ({ commit, dispatch }, topMenuId) {
      return new Promise(resolve => {
        getmenu(topMenuId).then((res) => {
          const data = res.data
          let menu = deepClone(data)
          menu && menu.forEach(ele => {
            addPath(ele, true)
          })
          commit('SET_MENU_ALL', menu)
          commit('SET_MENU', menu)
          dispatch('GetButtons')
          resolve(menu)
        })
      })
    },
    //获取系统按钮
    GetButtons ({ commit }) {
      return new Promise((resolve) => {
        getmenubuttons().then(res => {
          const data = res.data
          commit('SET_PERMISSION', data)
          resolve()
        })
      })
    },
  }

}
