<template>
  <el-dialog
    custom-class="customdialog"
    title="初始密码修改"
    :visible.sync="isshowdialog"
    width="55%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-alert
      title="您好，为了您的账号安全，请点击下一步修改初始密码"
      type="warning"
      show-icon
      :closable="false"
    >
    </el-alert>
    <div class="step">
      <el-steps :active="step" align-center>
        <el-step title="初次登录"></el-step>
        <el-step title="修改初始密码"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>

    <div class="con">
      <div class="form1" v-show="step == 1">
        <el-form ref="form" :model="form1" label-width="0">
          <el-form-item>
            <div class="label">登录账号：</div>
            <el-input v-model="form1.name" readonly></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="form2" v-show="step == 2">
        <el-form ref="form2" :model="form2" label-width="0">
          <el-form-item>
            <div class="label"><span class="red">*</span>原密码：</div>
            <el-input v-model="form2.oldPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item required>
            <div class="label">
              <span class="red">*</span>新密码：<span class="tips"
                >1.密码长度不能低于6个字符；2.密码必须由数字、字母、符号等至少两种类型混合组成</span
              >
            </div>
            <el-input v-model="form2.newPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item required>
            <div class="label"><span class="red">*</span>确认新密码：</div>
            <el-input v-model="form2.newPassword1" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="form3" v-show="step == 3">
        <i class="el-icon-success"></i>
        <div>密码修改成功</div>
      </div>

      <div class="wrapbtn">
        <div v-if="step == 1" style="display:flex">
           <div class="btn" @click="handelnext" v-if="step != 3">下一步</div>
        </div>
        <div v-if="step == 2" style="display:flex">
          <div class="btn btnpre" @click="handlepre" >
             上一步</div>
          <div class="btn" @click="handelnext2" v-if="step == 2">下一步</div>
        </div>
        
        <div class="btn" @click="handelok" v-if="step == 3">重新登录</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { updatepassword } from '@/api/patientManage'

export default {
  name: 'Editpassword',
  data () {
    return {
      step: 1,
      form1: {
        name: '',
      },
      form2: {
        newPassword: '',
        newPassword1: '',
        oldPassword: ''
      },
    }
  },
  computed: {
    isshowdialog(){
				return this.$store.state.user.isshowdialog
			},
      userInfo(){
				return this.$store.state.user.userInfo
			},
     
  },
  methods: {
    handlepre () {
      this.step = --this.step
    },
    async handelnext2 () {
      if (this.form2.oldPassword == '') {
        return this.$message({
          type: "error",
          message: "原密码不能为空",
        })
      }
      if (this.form2.newPassword == "") {
        return this.$message({
          type: "error",
          message: "新密码不能为空",
        })
      } else if (!this.validatePassword(this.form2.newPassword)) {
        return this.$message({
          type: "error",
          message: "密码格式不正确",
        })
      }
      if (this.form2.newPassword != this.form2.newPassword1) {
        return this.$message({
          type: "error",
          message: "确保两次输入的密码一致",
        })
      }
      let {code,msg } = await updatepassword({
        oldPassword:this.$md5(this.form2.oldPassword),
        newPassword:this.$md5(this.form2.newPassword),
        newPassword1:this.$md5(this.form2.newPassword1),
      })
      if(code == 200){
          this.step = 3
      }else {
        return this.$message({
            type: "error",
            message: msg,
          })
      }
      
    },
    async handelnext () {
      this.step = ++this.step



    },
    handleClose () {
      this.$store.commit("user/SET_ClOSEDIALOG")
    },
    handelok () {
      localStorage.clear()
      this.$router.replace({
        path: '/login',
      })
      this.$store.commit('user/SET_ClOSEDIALOG')
    },
    validatePassword (password) {
      // 密码长度不能低于6个字符
      if (password.length < 6) {
        return false
      }
      // 密码必须由数字、字母、符号等至少两种类型混合组成
      var regex = /^(?=.*[0-9])(?=.*[a-zA-Z!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]+$/
      return regex.test(password)
    },
  },
  updated() {
      this.form1.name = this.userInfo.user_name
  }
}
</script>

<style  lang="scss" scoped>
::v-deep .el-dialog.customdialog {
  .red {
    color: #db001c;
  }
  .step {
    padding-top: 40px;
  }

  .is-finish {
    .el-step__icon.is-text {
      background: #409eff;
      .el-step__icon-inner {
        color: #fff;
      }
    }
  }
  .con {
    .tips {
      color: rgba(0, 0, 0, 0.4);
    }

    margin: 0 50px;
    padding-top: 40px;
    position: relative;
    height: 400px;
    .wrapbtn {
      position: absolute;
      bottom: 64px;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .form1 {
      .el-input__inner {
        background: #f5f7fa;
      }
    }
    .label {
      height: 22px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      line-height: 22px;
      margin-bottom: 8px;
    }
    .btn {
      cursor: pointer;
      width: 162px;
      height: 32px;
      line-height: 32px;
      background: #1e7aff;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      box-sizing: border-box;
      margin: 0 12px;
    }
    .btnpre {
      background: transparent;
      border: 1px solid #1e7aff;
      color: #1e7aff;
    }
    .form3 {
      padding-top: 60px;
      height: 100%;
      .el-icon-success {
        color: #00cf85;
        font-size: 67px;
        margin-bottom: 10px;
      }
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .el-alert--warning.is-light {
    background-color: #fcf6ec;
    padding: 15px;
    color: #e6a23c;
    .el-alert__title {
      font-size: 14px;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  border-radius: 4px;
  .el-dialog__header {
    text-indent: 16px;
    font-size: 18px;
    height: 58px;
    line-height: 58px;
    background: #1e7aff;
    border-radius: 4px 4px 0px 0px;
    color: #fff;
    padding: 0;
    position: relative;

    .el-icon-full-screen {
      position: absolute;
      right: 48px;
      top: 20px;
    }
    .el-dialog__close,
    .el-dialog__title {
      color: #fff;
    }
  }
}
</style>